import React from "react";

const Home = () => {
  return (
    <div className="container mt-5 px-5">
      <section>
        <h4>GLACIIR was developed to provide students an interactive platform to learn how ice sheets respond to changes in climate. Read about the glacier model <a href="#">here</a>.</h4>
        <hr/>
        <h5>Introduction</h5>
        <p>
        Ice flows from the center of the ice sheet through outlet glaciers, which discharge ice to the ocean.
        </p>
        <a href="https://svs.gsfc.nasa.gov/3962" target="_blank">
          <div className="mb-2">
            <img src="greenland_flow.jpg" width="80%"/>
          </div>
        </a>
        <p>The image above is from a <a href="https://svs.gsfc.nasa.gov/3962" target="_blank">NASA article</a> illustrates that process very clearly.</p>
        <p>In the past few decades, we have observed large changes around the margins of the Greenland Ice Sheet, largely from these outlet glaciers accelerating and transporting more ice to the oceans. In particular, many glaciers have accelerated, thinned, and retreated.</p>
        <p>The video below from NASA and JPL/Caltech illustrates mass loss measured by the GRACE satellite program.  More information on GRACE can be found <a href="https://gracefo.jpl.nasa.gov/mission/overview/" target="_blank">here</a>.</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/sK4qz-h8o1M?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </section>
    </div>
  );
};

export default Home;
