import api from "../util/api";

export default {
  name: "modelRun",

  getReducer: () => {
    const initialData = {
      id: null, // if populated it's the currently running model
      _shouldHandleModelDone: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MODEL_OUTPUT_POLLING_FINISHED":
          return Object.assign({}, state, {
            _shouldHandleModelDone: true,
          });
        case "MODEL_RUN_FINISHED":
          return Object.assign({}, state, {
            id: payload.id,
          });
        case "MODEL_RUN_STARTED":
        case "MODEL_RUN_HANDLING_DONE_STARTED":
        case "MODEL_RUN_HANDLING_DONE_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doModelRun:
    () =>
    ({ dispatch, store }) => {
      const flowlineId = store.selectFlowlinesSelectSelectedFeatureId();
      const modelName = store.selectModelConfigModelName();
      const flowlineData = store.selectFlowlinesDataForModelRun();

      dispatch({
        type: "MODEL_RUN_STARTED",
        payload: {},
      });

      api.post(
        "models",
        Object.assign({}, flowlineData, {
          name: modelName,
          flowlineId: flowlineId,
        }),
        (data) => {
          dispatch({
            type: "MODEL_RUN_FINISHED",
            payload: data,
          });
        }
      );
    },

  doModelRunHandleModelDone:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MODEL_RUN_HANDLING_DONE_STARTED",
        payload: {
          _shouldHandleModelDone: false,
        },
      });
      const modelId = store.selectModelRunId();
      const models = store.selectModelOutputModels();
      const model = models[modelId];

      if (model && model.status === "complete") {
        store.doModelConfigOpen(model);
      } else {
        console.log("need to handle errors here");
      }

      dispatch({
        type: "MODEL_RUN_HANDLING_DONE_FINISHED",
        payload: {
          id: null,
        },
      });
    },

  selectModelRunId: (state) => {
    return state.modelRun.id;
  },

  reactModelRunShouldHandleModelDone: (state) => {
    if (state.modelRun._shouldHandleModelDone)
      return { actionCreator: "doModelRunHandleModelDone" };
  },
};
