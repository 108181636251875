import React from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "doHelpMessagesToggle",
  "selectHelpMessagesShow",
  ({ doHelpMessagesToggle, helpMessagesShow }) => {
    const expanded = helpMessagesShow[1];

    return (
      <div className="alert  alert-primary mt-3">
        <button
          style={{ position: "relative", top: "-5px" }}
          onClick={() => {
            doHelpMessagesToggle(1);
          }}
          className="float-right btn btn-sm btn-link"
        >
          {expanded ? (
            <i className="mdi mdi-close"></i>
          ) : (
            <i className="mdi mdi-dots-vertical"></i>
          )}
        </button>
        {expanded ? (
          <>
            <p className="">Welcome to Glaciir.</p>
            <p>
              Glaciir uses a one dimensional flow line model to help understand
              how glaciers respond to changes in environmental conditions over
              time.
            </p>
            <p className="mt-3">
              Each of the lines on the map to your left are flowlines that can
              be investigated here.
            </p>
            <p className="mt-3">
              Click on a flowline on the map or choose a glacier in the picklist
              below to get started.
            </p>
          </>
        ) : (
          <div>Welcome to Glaciir</div>
        )}
      </div>
    );
  }
);
