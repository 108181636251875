import flowlinesBundle from "./bundles/flowlines-bundle";
import flowlinesDataBundle from "./bundles/flowlines-data-bundle";
import flowlinesModelsBundle from "./bundles/flowlines-models-bundle";
import flowlinesSelectBundle from "./bundles/flowlines-select-bundle";
import helpMessagesBundle from "./bundles/help-messages-bundle";
import introPanelBundle from "./bundles/intro-panel-bundle";
import mapLayersBundleConstructor from "./bundles/map-layers-bundle-constructor";
import modelAnimationBundle from "./bundles/model-animation-bundle";
import modelConfigBundle from "./bundles/model-config-bundle";
import modelOutputBundle from "./bundles/model-output-bundle";
import modelRunBundle from "./bundles/model-run-bundle";
import plottingBundle from "./bundles/plotting-bundle";

export default (opts) => {
  if (opts.pole === "N") {
    return {
      bundle: [
        flowlinesBundle,
        flowlinesSelectBundle,
        modelConfigBundle,
        flowlinesDataBundle,
        helpMessagesBundle,
        introPanelBundle,
        flowlinesModelsBundle,
        mapLayersBundleConstructor("N"),
        modelAnimationBundle,
        modelOutputBundle,
        modelRunBundle,
        plottingBundle,
      ],
      components: [],
    };
  } else {
    return {
      bundle: [mapLayersBundleConstructor("S")],
      components: [],
    };
  }
};
