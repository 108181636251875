import api from "../util/api";
import { createSelector } from "redux-bundler";

export default {
  name: "flowlinesModels",

  getReducer: () => {
    const initialData = {
      cache: {},
      lastFetchedId: null,
      _shouldFetch: false,
      _shouldInit: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "FLOWLINES_SELECT_SELECTION_CHANGED":
        case "MODEL_RUN_HANDLING_DONE_FINISHED":
          return Object.assign({}, state, {
            _shouldFetch: true,
          });
        case "FLOWLINES_MODELS_FETCH_STARTED":
        case "FLOWLINES_MODELS_FETCH_FINISHED":
        case "FLOWLINES_MODELS_FETCH_ABORTED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFlowlinesModelsFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "FLOWLINES_MODELS_FETCH_STARTED",
        payload: {
          _shouldFetch: false,
        },
      });

      const id = store.selectFlowlinesSelectSelectedFeatureId();
      // const lastFetchedId = store.selectFlowlinesModelsLastFetchedId();

      // if (id !== lastFetchedId) {
        const cache = store.selectFlowlinesModelsCache();
        api.get(`flowline/${id}/models`, (data) => {
          dispatch({
            type: "FLOWLINES_MODELS_FETCH_FINISHED",
            payload: {
              cache: Object.assign({}, cache, {
                [id]: data,
              }),
              lastFetchedId: id,
            },
          });
        });
      // } else {
      //   dispatch({
      //     type: "FLOWLINES_MODELS_FETCH_ABORTED",
      //     payload: {},
      //   });
      // }
    },

  selectFlowlinesModelsCache: (state) => {
    return state.flowlinesModels.cache;
  },

  selectFlowlinesModels: createSelector(
    "selectFlowlinesSelectSelectedFeatureId",
    "selectFlowlinesModelsCache",
    (id, cache) => {
      if (cache.hasOwnProperty(id)) {
        return cache[id];
      } else {
        return [];
      }
    }
  ),

  selectFlowlinesModelsLastFetchedId: (state) => {
    return state.flowlinesModels.lastFetchedId;
  },

  reactFlowlinesModelsShouldFetch: (state) => {
    if (state.flowlinesModels._shouldFetch)
      return { actionCreator: "doFlowlinesModelsFetch" };
  },
};
