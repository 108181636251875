import proj4 from 'proj4';
import {get as getProjection, getTransform} from 'ol/proj';
import {register} from 'ol/proj/proj4';

proj4.defs("EPSG:3413", "+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs");
register(proj4);
const newProj = getProjection("EPSG:3413");
const fromLonLat = getTransform('EPSG:4326', newProj);

export default {
  name: 'zoomHome',

  getReducer: () => {
    const initialData = {
      coords: [-40, 72],
      zoom: 6,
    }

    return (state = initialData, { type, payload}) => {
      switch(type){
        case "MAP_INITIALIZED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    }
  },

  selectZoomHomeCoords: (state) => {
    return fromLonLat(state.zoomHome.coords)
  },

  selectZoomHomeZoom: (state) => {
    return state.zoomHome.zoom;
  },

  doZoomHome: () => ({dispatch, store}) => {
    const map = store.selectMap();
    const center = store.selectZoomHomeCoords();
    const zoom = store.selectZoomHomeZoom();
    const view = map.getView();
    view.animate({
      center,
      zoom
    })
  }
}