export default {
  name: "plotting",

  getReducer: () => {
    const initialData = {
      _shouldHandleSelectionChange: false,
      _shouldFetch: false,
      _shouldInit: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "FLOWLINES_SELECT_SELECTION_CHANGED":
          return Object.assign({}, state, {
            _shouldHandleSelectionChange: true,
          });
        case "PLOTTING_HANDLE_SELECTION_CHANGE_STARTED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doPlottingHandleSelectionChange:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PLOTTING_HANDLE_SELECTION_CHANGE_STARTED",
        payload: {
          _shouldHandleSelectionChange: false,
        },
      });
      const selectedFeature = store.selectFlowlinesSelectSelectedFeature();
      if (!selectedFeature) {
        store.doModelAnimationClose();
      } else {
        store.doModelAnimationOpen();
      }
    },

  reactPlottingShouldHandleSelectionChange: (state) => {
    if (state.plotting._shouldHandleSelectionChange)
      return { actionCreator: "doPlottingHandleSelectionChange" };
  },
};
