import React from "react";
import Card from "./model-card";

const Home = () => {
  return (
    <div className="container mt-5">
      <section className="mb-3">
        <div className="row">
          {["greenland", "antarctica"].map((title) => {
            return (
              <div key={title} className="col-md-6">
                <Card title={title} active={title==="greenland"}/>
              </div>
            );
          })}
        </div>
      </section>
      <hr />
      <section>
        <h3>Glaciir SIA based Glacier Model</h3>
        <p>
          Glaciir is based on the Shallow Ice Approximation glacier modeling
          approach. This model estimates the changes in ice surface elevation
          along a one dimensional centerline that tries to approximate the
          behavior of a glacier in response to changes in climate factors.
        </p>
        <p>Click on an ice sheet above to start exploring!</p>
      </section>
    </div>
  );
};

export default Home;
