import { Stroke, Style } from "ol/style";
import { Select } from "ol/interaction";
import { createSelector } from "redux-bundler";
import IntroPanel from "../components/intro-panel/intro-panel";

export default {
  name: "flowlinesSelect",

  getReducer: () => {
    const initialData = {
      interaction: null,
      selectedFeature: null,
      _shouldInit: false,
    };

    return (state = initialData, { type, payload }) => {
      if (process.env.NODE_ENV === "development") console.log(type, payload);
      switch (type) {
        case "FLOWLINES_INIT_FINISHED":
          return Object.assign({}, state, {
            _shouldInit: true,
          });
        case "FLOWLINES_SELECT_INIT_STARTED":
        case "FLOWLINES_SELECT_INIT_FINISHED":
        case "FLOWLINES_SELECT_SELECTION_CHANGED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFlowlinesSelectByIdHandler:
    (id) =>
    ({ store }) => {
      // clear current selection without firing an action
      const select = store.selectFlowlinesSelectInteraction();
      const features = select.getFeatures();
      features.clear();

      // only try and select if we sent an id
      if (id) {
        // get the feature by id and add to our selected features
        const layer = store.selectFlowlinesLayer();
        const src = layer.getSource();
        const feature = src.getFeatureById(id);
        features.push(feature);

        // mock the select event to re-use the handler
        store.doFlowlinesSelectHandler({ selected: [feature] });
      } else {
        // in this case we do want to fire the action to
        // clear out the store
        store.doFlowlinesSelectClear();
      }
    },

  doFlowlinesSelectClear:
    () =>
    ({ dispatch, store }) => {
      const select = store.selectFlowlinesSelectInteraction();
      const features = select.getFeatures();
      features.clear();
      dispatch({
        type: "FLOWLINES_SELECT_SELECTION_CHANGED",
        payload: {
          selectedFeature: null,
        },
      });
    },

  doFlowlinesSelectHandler:
    (e) =>
    ({ dispatch, store }) => {
      const feature = e.selected[0];
      if (feature) {
        const view = store.selectMap().getView();
        view.fit(feature.getGeometry().getExtent(), {
          duration: 200,
          padding: [50, 50, 50, 50],
        });
        dispatch({
          type: "FLOWLINES_SELECT_SELECTION_CHANGED",
          payload: {
            selectedFeature: feature,
          },
        });
      } else {
        // go back to intro panel if we're not already there
        const currentPanel = store.selectEastPanelContents();
        if (currentPanel !== IntroPanel) {
          store.doModelConfigClose();
        }
        dispatch({
          type: "FLOWLINES_SELECT_SELECTION_CHANGED",
          payload: {
            selectedFeature: null,
          },
        });
      }
    },

  doFlowlinesSelectInit:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "FLOWLINES_SELECT_INIT_STARTED",
        payload: {
          _shouldInit: false,
        },
      });

      const map = store.selectMap();
      const layer = store.selectFlowlinesLayer();

      const interaction = new Select({
        layers: [layer],
        style: new Style({
          stroke: new Stroke({
            color: "cyan",
            width: 6,
          }),
        }),
        hitTolerance: 3,
      });
      interaction.on("select", store.doFlowlinesSelectHandler);

      map.addInteraction(interaction);

      dispatch({
        type: "FLOWLINES_SELECT_INIT_FINISHED",
        payload: {
          interaction: interaction,
        },
      });
    },

  selectFlowlinesSelectInteraction: (state) => {
    return state.flowlinesSelect.interaction;
  },

  selectFlowlinesSelectSelectedFeature: (state) => {
    return state.flowlinesSelect.selectedFeature;
  },

  selectFlowlinesSelectSelectedFeatureId: createSelector(
    "selectFlowlinesSelectSelectedFeature",
    (feature) => {
      if (feature) return feature.getId();
      return null;
    }
  ),

  selectFlowlinesSelectSelectedFeatureTrend: createSelector(
    "selectFlowlinesSelectSelectedFeature",
    (feature) => {
      if (feature) return feature.get("trend");
      return null;
    }
  ),

  reactFlowlinesSelectShouldInit: (state) => {
    if (state.flowlinesSelect._shouldInit)
      return { actionCreator: "doFlowlinesSelectInit" };
  },
};
