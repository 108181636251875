import Panel from "../components/plotting/plotting-panel";

export default {
  name: "modelAnimation",

  getReducer: () => {
    const initialData = {
      currentFrame: 0,
      speed: 200, // ms per frame
      timer: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MODEL_ANIMATION_UPDATE":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doModelAnimationOpen:
    () =>
    ({ store }) => {
      store.doSouthPanelOpen(Panel);
    },

  doModelAnimationClose:
    () =>
    ({ store }) => {
      store.doSouthPanelClose();
    },

  doModelAnimationPlay:
    (speedOverride) =>
    ({ dispatch, store }) => {
      store.doModelAnimationStop();
      const speed = store.selectModelAnimationSpeed();
      console.log("SPEED", speedOverride, speed);
      const timer = window.setInterval(
        store.doModelAnimationForward,
        speedOverride || speed
      );
      dispatch({
        type: "MODEL_ANIMATION_UPDATE",
        payload: {
          timer: timer,
        },
      });
    },

  doModelAnimationStop:
    () =>
    ({ dispatch, store }) => {
      const timer = store.selectModelAnimationTimer();
      if (timer) {
        window.clearInterval(timer);
        dispatch({
          type: "MODEL_ANIMATION_UPDATE",
          payload: {
            timer: null,
          },
        });
      }
    },

  doModelAnimationBack:
    () =>
    ({ dispatch, store }) => {
      const frame = store.selectModelAnimationCurrentFrame();
      const newFrame = frame > 0 ? frame - 1 : 0;
      dispatch({
        type: "MODEL_ANIMATION_UPDATE",
        payload: {
          currentFrame: newFrame,
        },
      });
    },

  doModelAnimationForward:
    () =>
    ({ dispatch, store }) => {
      const length = store.selectModelAnimationRunLength();
      const frame = store.selectModelAnimationCurrentFrame();
      const newFrame = frame < length - 1 ? frame + 1 : 0;
      dispatch({
        type: "MODEL_ANIMATION_UPDATE",
        payload: {
          currentFrame: newFrame,
        },
      });
    },

  doModelAnimationSetFrame:
    (frame) =>
    ({ dispatch, store }) => {
      store.doModelAnimationStop();
      dispatch({
        type: "MODEL_ANIMATION_UPDATE",
        payload: {
          currentFrame: frame,
        },
      });
    },

  doModelAnimationRewind:
    (speedOverride) =>
    ({ dispatch, store }) => {
      store.doModelAnimationStop();
      const speed = store.selectModelAnimationSpeed();
      const timer = window.setInterval(
        store.doModelAnimationBack,
        speedOverride || speed
      );
      dispatch({
        type: "MODEL_ANIMATION_UPDATE",
        payload: {
          timer: timer,
        },
      });
    },

  selectModelAnimationIsAnimating: (state) => {
    return !!state.modelAnimation.timer;
  },

  selectModelAnimationCurrentFrame: (state) => {
    return Number(state.modelAnimation.currentFrame);
  },

  selectModelAnimationRunLength: (state) => {
    return 99;
  },

  selectModelAnimationTimer: (state) => {
    return state.modelAnimation.timer;
  },

  selectModelAnimationSpeed: (state) => {
    return state.modelAnimation.speed;
  },
};
