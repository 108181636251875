import React from "react";
import { connect } from "redux-bundler-react";
import Spinner from "../../../../app-components/spinner";

export default connect(
  "selectModelOutputIsPolling",
  ({ modelOutputIsPolling }) => {
    if (!modelOutputIsPolling) return null;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(0,0,0,0.2",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Spinner />
      </div>
    );
  }
);
