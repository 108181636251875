import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import classnames from "../utils/classnames";
import config from "../config";
import pkg from "../../package.json";

const Navbar = connect("selectPathname", ({ pathname }) => {
  const [showCollapsed, setShowCollapsed] = useState(false);
  const collapsedClass = classnames({
    collapse: true,
    "navbar-collapse": true,
    show: showCollapsed,
  });
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <a className="navbar-brand" href="/">
        <img
          alt="glaciir logo"
          src="glaciir_icon.png"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            height: "20px",
            width: "20px",
            marginRight: "6px",
            top: "-2px",
            position: "relative",
          }}
        ></img>
        {config.brand}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        aria-expanded={showCollapsed}
        aria-label="Toggle navigation"
        onClick={() => {
          setShowCollapsed(!showCollapsed);
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={collapsedClass} id="navbarColor01">
        <ul className="navbar-nav mr-auto">
          <li className={`nav-item ${pathname === "/" ? "active" : ""}`}>
            <a className="nav-link" href="/">
              {`${pkg.version}`}
              <span className="sr-only">(current)</span>
            </a>
          </li>
          <li
            className={`nav-item ${pathname === "/greenland" ? "active" : ""}`}
          >
            <a className="nav-link" title="Greenland" href="/greenland">
              Greenland
            </a>
          </li>
          <li
            disabled className={`nav-item ${pathname === "/antarctica" ? "active" : ""}`}
          >
            <a className="nav-link" title="Antarctica (Coming Soon)" href="/antarctica">
              Antarctica
            </a>
          </li>
          <li
            className={`nav-item ${pathname === "/exercises" ? "active" : ""}`}
          >
            <a className="nav-link" title="Student exercises" href="/exercises">
              Exercises
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
});

export default Navbar;
