import React from "react";

const Card = ({ title, imgSrc, active }) => {
  return (
    <div className="card">
      {
        active ? (
          <a href={`/${title}`}>
            <div
              className={`card-body`}
              style={{
                backgroundImage: `url(${title}.png)`,
                backgroundSize: "cover",
                height: "400px",
              }}
            />
          </a>
        ) : (
          
          <div
            className={`card-body`}
            style={{
              backgroundImage: `url(${title}-inactive.png)`,
              backgroundSize: "cover",
              height: "400px",
            }}
          >
            <div style={{    
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '32px',
                    fontWeight:'bold'
            }}>Coming Soon</div>
          </div>
        )
      }
    </div>
  );
};

export default Card;
