import config from "../../../config";

export default {
  get: (path, callback, onErr) => {
    return fetch(`${config.apiRoot}/${path}`, { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then(callback)
      .catch((err) => {
        console.log(err);
        if (onErr && typeof onErr === "function") onErr(err);
      });
  },
  post: (path, payload, callback, onErr) => {
    return fetch(`${config.apiRoot}/${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then(callback)
      .catch((err) => {
        console.log(err);
        if (onErr && typeof onErr === "function") onErr(err);
      });
  },
  root: () => {
    return config.apiRoot;
  },
};
