let root = process.env.REACT_APP_API_ROOT || "";

// in case root comes back as undefined
if (!root) root = "";

export default {
  brand: "GLACIIR",
  apiRoot: `${root}/api`,
  tilePrefix: `${root}/tiles/greenland`,
};
