import React from "react";
import { connect } from "redux-bundler-react";
import ConfigPanelSlider from "./config-panel-slider";
import ModelPollingIndicator from "./model-polling-indicator";

export default connect(
  "selectFlowlinesSelectSelectedFeature",
  "selectModelConfigPerturbs",
  "selectModelConfigModelName",
  "doModelConfigUpdate",
  "doModelConfigClose",
  "doModelRun",
  ({
    flowlinesSelectSelectedFeature: feature,
    modelConfigPerturbs,
    modelConfigModelName: modelName,
    doModelConfigUpdate,
    doModelConfigClose,
    doModelRun,
  }) => {
    return (
      <div className="container-fluid pt-2" style={{ overflow: "auto" }}>
        <div className="float-right">
          <button
            onClick={doModelConfigClose}
            className="btn btn-sm btn-link"
            title="Close current model run"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div>
          <strong>{feature.get("name")}</strong>
        </div>
        <div>Scenario:</div>
        <div>
          <input
            value={modelName}
            onChange={(e) => {
              doModelConfigUpdate({ modelName: e.target.value });
            }}
            type="text"
            className="form-control form-control-sm"
          ></input>
        </div>
        <ConfigPanelSlider
          label="Air Temperature (+/- °C)"
          defaultValue="0"
          value={modelConfigPerturbs.tempPerturb}
          min="-5"
          max="5"
          step="0.1"
          suffix="°"
          helperText="Adjust the air temperature relative to baseline"
          onChange={(val) => {
            doModelConfigUpdate({ tempPerturb: val });
          }}
        />
        <ConfigPanelSlider
          label="Precipitation (%)"
          defaultValue="100"
          value={modelConfigPerturbs.precipPerturb}
          min="0"
          max="200"
          step="1"
          suffix="%"
          helperText="Adjust the precipitation relative to baseline"
          onChange={(val) => {
            doModelConfigUpdate({ precipPerturb: val });
          }}
        />
        <ConfigPanelSlider
          label="Sliding (%)"
          defaultValue="100"
          value={modelConfigPerturbs.frictPerturb}
          min="0"
          max="200"
          step="1"
          suffix="%"
          helperText="Adjust the percentage of calculated sliding that should be applied."
          onChange={(val) => {
            doModelConfigUpdate({ frictPerturb: val });
          }}
        />
        <div className="float-right">
          <button
            className="btn btn-primary"
            disabled={!modelName}
            onClick={doModelRun}
          >
            Run Model
          </button>
        </div>
        <ModelPollingIndicator visible={true} />
      </div>
    );
  }
);
