import React from "react";
import { connect } from "redux-bundler-react";
import Chart from "./flowline-data-chart";

const Panel = connect(
  "selectFlowlinesDataCompositeSeries",
  "selectModelOutputSeries",
  "selectModelAnimationCurrentFrame",
  ({
    flowlinesDataCompositeSeries,
    modelOutputSeries,
    modelAnimationCurrentFrame,
  }) => {
    if (!flowlinesDataCompositeSeries) return null;
    const series = [...flowlinesDataCompositeSeries];

    if (modelOutputSeries) {
      series.push({
        x: modelOutputSeries[modelAnimationCurrentFrame].surface.x,
        y: modelOutputSeries[modelAnimationCurrentFrame].surface.y,
        name: "Modeled Surface Elev.",
        type: "scattergl",
        mode: "lines",
        marker: { color: "blue" },
      });
      // series.push({
      //   x: modelOutputSeries[modelAnimationCurrentFrame].US0.x,
      //   y: modelOutputSeries[modelAnimationCurrentFrame].US0.y.map((y, i) => {
      //     return y * 1;
      //   }),
      //   name: "",
      //   type: "scattergl",
      //   mode: "lines",
      //   marker: { color: "brown" },
      //   yaxis: "y2",
      // });
      series.push({
        x: modelOutputSeries[modelAnimationCurrentFrame].US1.x,
        y: modelOutputSeries[modelAnimationCurrentFrame].US1.smoothed.map((y, i) => {
          return y * 10;
        }),
        name: "Modeled Surface Velocity",
        type: "scattergl",
        mode: "lines",
        marker: { color: "black" },
        yaxis: "y2",
      });
    }

    return (
      <div
        style={{
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "100%",
        }}
      >
        <Chart data={series} />
      </div>
    );
  }
);

export default Panel;
