import {
  composeBundles,
  createCacheBundle,
  createUrlBundle,
} from "redux-bundler";
import cache from "../utils/cache";
import modalWindowBundle from "./modal-window-bundle";
import routeBundle from "./route-bundle";

export default composeBundles(
  createCacheBundle({
    cacheFn: cache.set,
  }),
  createUrlBundle(),
  modalWindowBundle,
  routeBundle
);
