import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "doModelAnimationPlay",
  "doModelAnimationRewind",
  "doModelAnimationStop",
  "doModelAnimationSetFrame",
  "selectModelAnimationIsAnimating",
  "selectModelAnimationCurrentFrame",
  "selectModelAnimationRunLength",
  ({
    doModelAnimationPlay,
    doModelAnimationRewind,
    doModelAnimationStop,
    doModelAnimationSetFrame,
    modelAnimationIsAnimating,
    modelAnimationCurrentFrame,
    modelAnimationRunLength,
  }) => {
    // stop animation on unmount if we close the panel while looping
    useEffect(() => {
      return doModelAnimationStop;
    }, [doModelAnimationStop]);

    return (
      <div>
        <div>
          <span className="float-right">Year {modelAnimationCurrentFrame}</span>
          <button
            className="btn btn-primary"
            onClick={() => {
              doModelAnimationRewind(100);
            }}
          >
            <i className="mdi mdi-rewind"></i>
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              modelAnimationIsAnimating
                ? doModelAnimationStop()
                : doModelAnimationPlay();
            }}
          >
            <i
              className={`mdi ${
                modelAnimationIsAnimating ? "mdi-pause" : "mdi-play"
              }`}
            ></i>
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              doModelAnimationPlay(100);
            }}
          >
            <i className="mdi mdi-fast-forward"></i>
          </button>
        </div>
        <div className="mt-2">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "stretch",
            }}
          >
            <input
              value={modelAnimationCurrentFrame}
              onChange={(e) => {
                doModelAnimationSetFrame(e.target.value);
              }}
              type="range"
              className="custom-range"
              min={0}
              max={modelAnimationRunLength}
              step={1}
            />
          </div>
        </div>
      </div>
    );
  }
);
