import React from "react";
import glaciir from "../../cm-plugins/glaciir";
import zoomHome from '../../cm-plugins/zoom-home';
import getFlowline from '../../cm-plugins/get-flowline';
import {
  simpleCoordinateDisplay,
  scalebar,
  CorpsMap,
  getViewFromProjection,
  layerTree,
} from "@corpsmap/cm";
import "@corpsmap/cm/dist/cm.css";

const view = getViewFromProjection({
  epsg: "EPSG:3413",
  def: "+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs",
  centerLonLat: [-40, 72],
  zoom: 6,
});

const Map = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 54,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <CorpsMap
        plugins={[
          glaciir({ pole: "N" }),
          simpleCoordinateDisplay,
          scalebar({ units: "metric" }),
          layerTree,
          zoomHome,
        ]}
        view={view}
      />
    </div>
  );
};

export default Map;
