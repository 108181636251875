import { createRouteBundle } from "redux-bundler";
import Home from "../app-modules/home/home";
import Greenland from "../app-modules/greenland/map";
import Antarctica from "../app-modules/antarctica/map";
import Exercises from '../app-modules/exercises'

export default createRouteBundle({
  "": Home,
  "/": Home,
  "/antarctica": Antarctica,
  "/greenland": Greenland,
  "/exercises": Exercises,
  "*": Home,
});
