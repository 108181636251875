import glaciers from './glacier_pts_geojson.json'
import GeoJSON from 'ol/format/GeoJSON';
import {Circle as CircleStyle, Stroke, Style, Text, Fill} from 'ol/style';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import Select from 'ol/interaction/Select'
import dynamod2geojson from './dynamod2geojson';

const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({color: 'red', width: 1}),
});

let style = new Style({
  image: image,
  text: new Text({
    text: '',
    fill: new Fill({color: "#fff"}),
    // stroke: new Stroke({color: "#ffffff", width: 1}),
  })
});

let styleFunction = function (feature, resolution) {
  style.getText().setText(feature.get("Official_n"));
  return style;
}

const vectorSource = new VectorSource({
  features: new GeoJSON({dataProjection:  "EPSG:3413", featureProjection:  "EPSG:3413"}).readFeatures(glaciers),
});

const vectorLayer = new VectorLayer({
  source: vectorSource,
  style: styleFunction,
  zIndex: 9999999
});

export default {
  name: "getFlowline",

  getReducer: () => {
    const initialData = {
      selectInteraction: null,
      _shouldInit: false
    }

    return (state = initialData, { type, payload}) => {
      switch(type){
        case "MAP_INITIALIZED":
          return Object.assign({}, state, {
            _shouldInit: true
          })
        case 'GET_FLOWLINE_INIT_STARTED':
        case 'GET_FLOWLINE_INIT_FINISHED':
          return Object.assign({}, state, payload)
        default:
          return state
      }
    }
  },

  doGetFlowlineInit: () => ({ dispatch, store }) => {
    dispatch({
      type: 'GET_FLOWLINE_INIT_STARTED',
      payload:{
        _shouldInit: false
      }
    })
    const map = store.selectMap();

    map.on('dblclick', function(e) {
      e.preventDefault();
      e.stopPropagation();
      const coord = map.getCoordinateFromPixel(e.pixel);
      store.doGetFlowline(coord);
    });

    map.addLayer(vectorLayer)

    const select = new Select({
      layers: [vectorLayer],
      hitTolerance: 5
    })
    map.addInteraction(select)

    dispatch({
      type: 'GET_FLOWLINE_INIT_FINISHED',
      payload:{
        selectInteraction: select
      }
    })
  },

  doGetFlowline: (coord) => ({ dispatch, store}) => {
    const select = store.selectGetFlowlineSelectInteraction();
    const feature = select.getFeatures().item(0)
    if(!feature) return null;
    const id = feature.get('GlacierID')
    const name = feature.get('Official_n')
    const trend = feature.get('direction')
    const apiUrl = "http://localhost:8080/ajax/get_flowline_with_data/"
    const params = {
      "x": Number(coord[0].toFixed(2)),
      "y": Number(coord[1].toFixed(2)),
      "threshold": "10",
      "extLength": 20,
      "which": "Greenland"
    }

    console.log(id, name)
    fetch(apiUrl, {method: 'POST', body: JSON.stringify(params)}).then((response) => {
      console.log('ok: ', response.ok, response.status)
      return response.json()
    }).then((data) => {
      const geojson = dynamod2geojson(data)
      store.doGetFlowlineSave(geojson, name, trend)
    }).catch((err) => {
      console.log(`${err} error with ${name}`)
    });
  },

  doGetFlowlineSave: (geojson, name, trend) => ({ dispatch, store }) => {
    console.log(geojson)
    fetch(`http://localhost:3030/api/flowline/${name}/${trend}`,
      {method: 'POST', body: JSON.stringify(geojson), headers: {'Content-Type': 'application/json'},}
    ).then((response) => {
      if(response.ok){
        dispatch({
          type: 'GET_FLOWLINE_SUCCESS',
          payload: {}
        })
      }
    }).catch((err) => {
      console.log(`${err} error with ${name}`)
    })
  },

  selectGetFlowlineSelectInteraction: (state) => {
    return state.getFlowline.selectInteraction;
  },

  reactGetFlowlineShouldInit: (state) => {
    if(state.getFlowline._shouldInit) return { actionCreator: 'doGetFlowlineInit'}
  }
}