import React from "react";

export default ({ min, max, gradient }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <span>{min}</span>
      <span
        style={{
          width: "100%",
          marginRight: "8px",
          marginLeft: "8px",
          background: gradient,
        }}
      ></span>
      <span>{max}</span>
    </div>
  );
};
