import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import ImageWMS from "ol/source/ImageWMS";
import { Image as ImageLayer } from "ol/layer";
import proj4 from "proj4";
import { get as getProjection, getTransform } from "ol/proj";
import { applyTransform } from "ol/extent";
import { register } from "ol/proj/proj4";
import RasterLegend from "../components/legend/raster-legend";

// math for antarctic projection tiles
proj4.defs(
  "EPSG:3031",
  "+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs"
);
register(proj4);
const projection = getProjection("EPSG:3031");

const fromLonLat = getTransform("EPSG:4326", projection);
const bbox = [-60, -180, -90, 180];
let worldExtent = [bbox[1], bbox[2], bbox[3], bbox[0]];
projection.setWorldExtent(worldExtent);

if (bbox[1] > bbox[3]) {
  worldExtent = [bbox[1], bbox[2], bbox[3] + 360, bbox[0]];
}
const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
projection.setExtent(extent);

export default (pole) => {
  let layers = [];
  if (pole === "N") {
    const s3root = "https://glaciir.s3.us-east-2.amazonaws.com";
    layers = [
      {
        displayName: "Surface Elevation",
        mapLayer: new TileLayer({
          visible: false,
          source: new XYZ({
            url: `${s3root}/tiles/surface_elev/{z}/{x}/{y}.png`,
          }),
          zIndex: 10,
        }),
        legend: () => {
          return (
            <RasterLegend
              min={"0m"}
              max={"3540m"}
              gradient="linear-gradient(90deg, rgba(13,37,20,1) 0%, rgba(167,140,63,1) 42%, rgba(249,253,228,1) 100%)"
            />
          );
        },
      },
      {
        displayName: "Bedrock Elevation",
        mapLayer: new TileLayer({
          visible: false,
          source: new XYZ({
            url: `${s3root}/tiles/bedrock_elev/{z}/{x}/{y}.png`,
          }),
          zIndex: 11,
        }),
        legend: () => {
          return (
            <RasterLegend
              min={"-5474m"}
              max={"5474m"}
              gradient="linear-gradient(90deg, #281a2c 0%, #2f223a 2%, #352949 4%, #3a3058 6%, #3f3869 8%, #41407a 10%, #414988 12%, #3f5490 14%, #3e5f93 16%, #3e6996 18%, #407398 20%, #437d9a 22%, #46879c 24%, #49919e 25%, #4d9ba1 27%, #51a5a2 29%, #56afa4 31%, #5dbaa4 33%, #67c3a4 35%, #76cda3 37%, #89d5a3 39%, #9fdda5 41%, #b5e4aa 43%, #caebb2 45%, #dff2bb 47%, #f3fac6 49%, #0f2915 51%, #143218 53%, #183a1b 55%, #1c431d 57%, #214c1e 59%, #2d531f 61%, #3c5a26 63%, #49602f 65%, #566635 67%, #646c3a 69%, #71723d 71%, #7e793f 73%, #8c7f40 75%, #998540 76%, #a78c3f 78%, #b6923f 80%, #c39945 82%, #c8a357 84%, #cead68 86%, #d3b87a 88%, #d8c38b 90%, #decf9d 92%, #e4daae 94%, #eae6c0 96%, #f1f1d2 98%, #f9fde4 100%)"
            />
          );
        },
      },
      {
        displayName: "Ice Thickness",
        mapLayer: new TileLayer({
          visible: false,
          source: new XYZ({
            url: `${s3root}/tiles/thickness/{z}/{x}/{y}.png`,
          }),
          zIndex: 12, // try and keep it below the flowlines
        }),
        legend: () => {
          return (
            <RasterLegend
              min={"0m"}
              max={"3427m"}
              gradient="linear-gradient(90deg, #040613 0%, #080a1a 2%, #0d0e21 4%, #121328 6%, #17172f 8%, #1b1b37 10%, #1f1f3e 12%, #242246 14%, #28264e 16%, #2b2954 18%, #2f2e5e 20%, #333266 22%, #36356e 24%, #393976 25%, #3b3e7f 27%, #3d4287 29%, #3e478f 31%, #3f4a95 33%, #3f509b 35%, #3f56a2 37%, #3e5ca7 39%, #3e61ab 41%, #3e66ae 43%, #3f6cb2 45%, #4072b4 47%, #4178b6 49%, #437db8 51%, #4682ba 53%, #4988bc 55%, #4c8dbe 57%, #5092c0 59%, #5397c1 61%, #589dc3 63%, #5da2c5 65%, #61a8c7 67%, #67adc9 69%, #6cb2cb 71%, #72b8ce 73%, #78bdd0 75%, #7dc1d1 76%, #86c7d4 78%, #8eccd6 80%, #95d0d8 82%, #a0d6dc 84%, #a8d9de 86%, #b3dfe3 88%, #bce4e7 90%, #c4e7ea 92%, #cfedef 94%, #d8f2f4 96%, #e1f7f9 98%, #eafdfd 100%)"
            />
          );
        },
      },
      {
        displayName: "Surface Velocity",
        mapLayer: new TileLayer({
          visible: true,
          source: new XYZ({
            url: `${s3root}/tiles/velocity/{z}/{x}/{y}.png`,
          }),
          zIndex: 13,
        }),
        legend: () => {
          return (
            <RasterLegend
              min={"0m/y"}
              max={"500m/y"}
              gradient="linear-gradient(90deg, #0d0887 0%, #1b068d 2%, #260591 4%, #2f0596 6%, #38049a 8%, #41049d 10%, #4903a0 12%, #5102a3 14%, #5901a5 16%, #6100a7 18%, #6900a8 20%, #7100a8 22%, #7801a8 24%, #8004a8 25%, #8707a6 27%, #8e0ca4 29%, #9511a1 31%, #9c179e 33%, #a21d9a 35%, #a82296 37%, #ae2892 39%, #b42e8d 41%, #ba3388 43%, #bf3984 45%, #c43e7f 47%, #c9447a 49%, #cd4a76 51%, #d24f71 53%, #d6556d 55%, #da5b69 57%, #de6164 59%, #e26660 61%, #e66c5c 63%, #e97257 65%, #ed7953 67%, #f07f4f 69%, #f3854b 71%, #f58c46 73%, #f79342 75%, #f99a3e 76%, #fba139 78%, #fca835 80%, #fdaf31 82%, #feb72d 84%, #febe2a 86%, #fdc627 88%, #fcce25 90%, #fbd724 92%, #f8df25 94%, #f6e826 96%, #f3f027 98%, #f0f921 100%)"
            />
          );
        },
      },
    ];
  } else {
    layers = [
      {
        displayName: "Surface Elevation",
        mapLayer: new ImageLayer({
          visible: true,
          zIndex: 14,
          extent: extent,
          source: new ImageWMS({
            url: "https://glaciir.dev/mapserv?MAP=/u02/glaciir.map",
            params: { LAYERS: "ant_surface_elev" },
            ratio: 1,
            serverType: "mapserver",
          }),
        }),
      },
      {
        displayName: "Bedrock Elevation",
        mapLayer: new ImageLayer({
          visible: false,
          zIndex: 13,
          extent: extent,
          source: new ImageWMS({
            url: "https://glaciir.dev/mapserv?MAP=/u02/glaciir.map",
            params: { LAYERS: "ant_bed_elev" },
            ratio: 1,
            serverType: "mapserver",
          }),
        }),
      },
      {
        displayName: "Thickness",
        mapLayer: new ImageLayer({
          visible: false,
          zIndex: 12,
          extent: extent,
          source: new ImageWMS({
            url: "https://glaciir.dev/mapserv?MAP=/u02/glaciir.map",
            params: { LAYERS: "ant_thickness" },
            ratio: 1,
            serverType: "mapserver",
          }),
        }),
      },
      {
        displayName: "Surface Velocity",
        mapLayer: new ImageLayer({
          visible: false,
          zIndex: 11,
          extent: extent,
          source: new ImageWMS({
            url: "https://glaciir.dev/mapserv?MAP=/u02/glaciir.map",
            params: { LAYERS: "ant_velocity" },
            ratio: 1,
            serverType: "mapserver",
          }),
        }),
      },
    ];
  }

  return {
    name: "mapLayers",

    getReducer: () => {
      const initialData = {
        _shouldInit: false,
      };

      return (state = initialData, { type, payload }) => {
        switch (type) {
          case "MAP_INITIALIZED":
            return Object.assign({}, state, {
              _shouldInit: true,
            });
          case "MAP_LAYERS_INIT_STARTED":
          case "MAP_LAYERS_INIT_FINISHED":
            return Object.assign({}, state, payload);
          default:
            return state;
        }
      };
    },

    doMapLayersInit:
      () =>
      ({ dispatch, store }) => {
        dispatch({
          type: "MAP_LAYERS_INIT_STARTED",
          payload: {
            _shouldInit: false,
          },
        });

        layers.forEach((layer) => {
          store.doLayersAdd({
            displayName: layer.displayName,
            mapLayer: layer.mapLayer,
            legend: layer.legend || null,
          });
        });

        dispatch({
          type: "MAP_LAYERS_INIT_FINISHED",
          payload: {},
        });
      },

    reactMapLayersShouldInit: (state) => {
      if (state.mapLayers._shouldInit)
        return { actionCreator: "doMapLayersInit" };
    },
  };
};
