import React from "react";
import { connect } from "redux-bundler-react";
import {ToolbarButton} from "@corpsmap/cm";

export default connect(
  "doZoomHome",
  ({ doZoomHome }) => {
    const handleClick = () => {
      doZoomHome();
    };
    return (
      <ToolbarButton
        active={false}
        title="Zoom to Ice Sheet"
        iconClass="mdi mdi-home"
        onClick={handleClick}
      />
    );
  }
);