import { createSelector } from "redux-bundler";

// implement our own caching in this bundle since we don't have access
// to the external app  redux store, this bundle is wrapped in
// corpsmaps redux store where we can't use `persistActions` right now because
// side-loaded bundles don't have access to the cache.
import { getConfiguredCache } from "money-clip";
import pkg from "../../../../package.json";

const cache = getConfiguredCache({
  maxAge: 1000 * 60 * 60 * 24,
  version: `${pkg.name}-internal-${pkg.version}`,
});

const bundle = {
  name: "helpMessages",

  getReducer: () => {
    let initialData = {
      _shouldCache: false,
      1: true, // Intro panel help
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "HELP_MESSAGES_CACHE":
        case "HELP_MESSAGES_UPDATE":
        case "HELP_MESSAGES_STATE_LOADED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doHelpMessagesSetState:
    (state) =>
    ({ dispatch }) => {
      dispatch({
        type: "HELP_MESSAGES_STATE_LOADED",
        payload: state,
      });
    },

  doHelpMessagesCache:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "HELP_MESSAGES_CACHE",
        payload: {
          _shouldCache: false,
        },
      });
      const state = store.selectHelpMessagesState();
      cache.set("helpMessages", state);
    },

  doHelpMessagesToggle:
    (id) =>
    ({ dispatch, store }) => {
      const show = store.selectHelpMessagesShow();
      dispatch({
        type: "HELP_MESSAGES_UPDATE",
        payload: {
          [id]: !show[id],
          _shouldCache: true,
        },
      });
    },

  selectHelpMessagesState: (state) => {
    return state.helpMessages;
  },

  selectHelpMessagesShow: createSelector("selectHelpMessagesState", (state) => {
    const items = {};
    Object.keys(state).forEach((key) => {
      if (key[0] !== "_") items[key] = state[key];
    });
    return items;
  }),

  selectHelpMessagesCache: async (state) => {
    const data = await cache.getAll().then((data) => {
      return data;
    });
    return data;
  },

  reactHelpMessagesShouldCache: (state) => {
    if (state.helpMessages._shouldCache)
      return { actionCreator: "doHelpMessagesCache" };
  },

  init: (store) => {
    cache.getAll().then((data) => {
      console.log(".....", data);
      store.doHelpMessagesSetState(data.helpMessages);
    });
  },
};

export default bundle;
