import { createSelector } from "redux-bundler";
import config from "../../../config";
import Panel from "../components/model-output-panel/output-panel";

export default {
  name: "modelOutput",

  getReducer: () => {
    const initialData = {
      isPolling: false,
      _shouldStartPolling: null,
      _shouldFetch: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        // model was opened, let's grab cached results
        case "MODEL_CONFIG_OPENED":
          return Object.assign({}, state, {
            _shouldStartPolling: payload.id,
          });
        // new model has been created, we should look for results
        case "MODEL_RUN_FINISHED":
          return Object.assign({}, state, {
            _shouldStartPolling: payload.id,
          });
        case "MODEL_OUTPUT_FETCH_STARTED":
        case "MODEL_OUTPUT_FETCH_FINISHED":
        case "MODEL_OUTPUT_FETCH_FAILED":
        case "MODEL_OUTPUT_POLLING_STARTED":
        case "MODEL_OUTPUT_POLLING_FINISHED":
        case "MODEL_OUTPUT_POLLING_FAILED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doModelOutputOpen:
    () =>
    ({ store }) => {
      store.doEastPanelOpen(Panel);
    },

  doModelOutputFetch:
    (id) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MODEL_OUTPUT_FETCH_STARTED",
        payload: {
          _shouldFetch: null,
        },
      });

      const models = store.selectModelOutputModels();
      const model = models[id];
      const trend = store.selectFlowlinesSelectSelectedFeatureTrend();

      fetch(`${config.apiRoot}/model-output/${model.hash}.out.json`)
        .then((response) => {
          if (response.ok) return response.json();
        })
        .then((data) => {
          // spin our line around if we're on the west coast, not super efficient, but
          // until we get the model to do this for us, probably the best approach
          if (trend === "r2l") {
            Object.keys(data.data).forEach((i) => {
              const series = data.data[i];
              series.surface.x = series.surface.x.map((xVal) => {
                return data.maxX - 1 - xVal;
              });
              series.US0.x = series.US0.x.map((xVal) => {
                return data.maxX - 1 - xVal;
              });
              series.US1.x = series.US1.x.map((xVal) => {
                return data.maxX - 1 - xVal;
              });
            });
          }

          // smooth our surface velocity, window centered on subject point
          const windowSize = 10
          const halfWindow = Math.floor(windowSize/2)
          Object.keys(data.data).forEach((i) => {
            const series = data.data[i];
            series.US1.smoothed = series.US1.y.map((_, k, arr) => {
              let start = 0;
              let end = arr.length;
              if(k > halfWindow) start = k - halfWindow;
              if(k < arr.length - halfWindow) end = k + halfWindow;
              const subset = arr.slice(start, end);
              const mean = subset.reduce((p, c) => {
                return c + p;
              }) / subset.length;
              const max = Math.max(...subset)
              return mean
            })
          })

          store.doModelAnimationSetFrame(0);

          dispatch({
            type: "MODEL_OUTPUT_FETCH_FINISHED",
            payload: {
              [model.id]: Object.assign({}, model, { output: data }),
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: "MODEL_CONFIG_FETCH_FAILED",
            payload: {
              fetchErr: err,
            },
          });
        });
    },

  doModelOutputStartPolling:
    (id) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MODEL_OUTPUT_POLLING_STARTED",
        payload: {
          isPolling: true,
          _shouldStartPolling: null,
        },
      });

      (function poll() {
        fetch(`${config.apiRoot}/models/${id}`)
          .then((response) => {
            if (response.ok) return response.json();
          })
          .then((data) => {
            if (data.status !== "processing") {
              dispatch({
                type: "MODEL_OUTPUT_POLLING_FINISHED",
                payload: {
                  isPolling: false,
                  [data.id]: data,
                  _shouldFetch: data.status === "complete" ? data.id : null,
                },
              });
              window.clearTimeout(poll)
            } else {
              window.setTimeout(poll, 2500);
            }
          })
          .catch((err) => {
            dispatch({
              type: "MODEL_CONFIG_POLLING_FAILED",
              payload: {
                isPolling: false,
                fetchErr: err,
              },
            });
          });
      })();
    },

  selectModelOutputModels: (state) => {
    const models = {};
    Object.keys(state.modelOutput)
      .filter((key) => {
        return key.indexOf("_") !== 0;
      })
      .forEach((id) => {
        models[id] = state.modelOutput[id];
      });
    return models;
  },

  selectModelOutputSeries: createSelector(
    "selectModelConfigId",
    "selectModelOutputModels",
    (id, models) => {
      if (Object.values(models).length === 0) return null;
      const model = models[id];
      if (model && model.hasOwnProperty("output")) {
        return model.output.data;
      } else {
        return null;
      }
    }
  ),

  selectModelOutputIsPolling: (state) => {
    return state.modelOutput.isPolling;
  },

  reactModelOutputShouldStartPolling: (state) => {
    if (!!state.modelOutput._shouldStartPolling)
      return {
        actionCreator: "doModelOutputStartPolling",
        args: [state.modelOutput._shouldStartPolling],
      };
  },

  reactModelOutputShouldFetch: (state) => {
    if (!!state.modelOutput._shouldFetch)
      return {
        actionCreator: "doModelOutputFetch",
        args: [state.modelOutput._shouldFetch],
      };
  },
};
