import { Stroke, Style } from "ol/style";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import api from "../util/api";
import { createSelector } from "redux-bundler";

export default {
  name: "flowlines",

  getReducer: () => {
    const initialData = {
      layer: null,
      _shouldFetch: false,
      _shouldInit: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MAP_INITIALIZED":
          return Object.assign({}, state, {
            _shouldInit: true,
          });
        case 'GET_FLOWLINE_SUCCESS':
          return Object.assign({}, state, {
            _shouldFetch: true,
          });
        case "FLOWLINES_FETCH_STARTED":
        case "FLOWLINES_FETCH_FINISHED":
        case "FLOWLINES_FETCH_RESTARTED":
        case "FLOWLINES_INIT_STARTED":
        case "FLOWLINES_INIT_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFlowlinesFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "FLOWLINES_FETCH_STARTED",
        payload: {
          _shouldFetch: false,
        },
      });

      const icesheet = window.location.pathname;
      const layer = store.selectFlowlinesLayer();
      const src = layer.getSource();

      api.get(`flowlines${icesheet}`, (data) => {
        const features = new GeoJSON().readFeatures(data, {
          dataProjection: "EPSG:4326",
          featureProjection:
            icesheet === "/greenland" ? "EPSG:3413" : "EPSG:3031",
        });
        features.forEach((f) => {
          const id = f.get("id");
          if (id) f.setId(id);
        });
        src.addFeatures(features);
        dispatch({
          type: "FLOWLINES_FETCH_FINISHED",
          payload: {
            _shouldFetch: false,
          },
        });
      });
    },

  doFlowlinesInit:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "FLOWLINES_INIT_STARTED",
        payload: {
          _shouldInit: false,
        },
      });

      const map = store.selectMap();

      const layer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          stroke: new Stroke({
            color: "green",
            width: 4,
          }),
        }),
        zIndex: 100, // above the basemaps
      });

      map.addLayer(layer);

      dispatch({
        type: "FLOWLINES_INIT_FINISHED",
        payload: {
          layer: layer,
          _shouldFetch: true,
        },
      });
    },

  selectFlowlinesFeatures: createSelector("selectFlowlinesLayer", (layer) => {
    if (!layer) return [];
    return layer.getSource().getFeatures();
  }),

  selectFlowlinesLayer: (state) => {
    return state.flowlines.layer;
  },

  reactFlowlinesShouldFetch: (state) => {
    if (state.flowlines._shouldFetch)
      return { actionCreator: "doFlowlinesFetch" };
  },

  reactFlowlinesShouldInit: (state) => {
    if (state.flowlines._shouldInit)
      return { actionCreator: "doFlowlinesInit" };
  },
};
