import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import Plot from "react-plotly.js";

const ModelOutputChart = connect(
  "doFlowlinesDataAddHighlight",
  "doFlowlinesDataClearHighlight",
  ({ doFlowlinesDataAddHighlight, doFlowlinesDataClearHighlight, data }) => {
    const [plotData, setData] = useState(data);
    const [layout, setLayout] = useState({});
    const [config, setConfig] = useState({});

    useEffect(() => {
      if (!data) return undefined;
      setData(data);
    }, [data]);

    useEffect(() => {
      setLayout({
        autosize: true,
        dragmode: "pan",
        xaxis: {
          title: {
            text: "Distance Along Centerline (km)",
            standoff: 0,
          },
          rangemode: "tozero",
        },
        yaxis: {
          title: "Elevation (m)",
          rangemode: "tozero",
        },
        yaxis2: {
          title: "Obs. Velocity (m/a)",
          overlaying: "y",
          side: "right",
        },
        showlegend: true,
        legend: { orientation: "h", y: -0.25 },
      });
    }, []);

    useEffect(() => {
      setConfig({
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: [
          "select2d",
          "lasso2d",
          "zoomIn2d",
          "zoomOut2d",
        ],
        scrollZoom: true,
      });
    }, []);

    const handleUpdate = ({ data, layout, config }) => {
      setData(data);
      setLayout(layout);
      setConfig(config);
    };

    const handleHover = (d) => {
      doFlowlinesDataClearHighlight();
      const flowline_pts = d.points.filter((pt) => {
        return pt.curveNumber === 2;
      });
      if (flowline_pts.length && flowline_pts[0].id)
        doFlowlinesDataAddHighlight(flowline_pts[0].id);
    };

    const handleUnhover = () => {
      doFlowlinesDataClearHighlight();
    };

    return (
      <Plot
        onInitialized={handleUpdate}
        onUpdate={handleUpdate}
        onHover={handleHover}
        onUnhover={handleUnhover}
        revision={0}
        style={{ width: "100%", height: "100%" }}
        data={plotData}
        layout={layout}
        config={config}
      />
    );
  }
);

export default ModelOutputChart;
