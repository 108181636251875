import React from "react";
import { connect } from "redux-bundler-react";

export default connect(
  "doFlowlinesSelectByIdHandler",
  "selectFlowlinesFeatures",
  "selectFlowlinesSelectSelectedFeatureId",
  ({
    doFlowlinesSelectByIdHandler,
    flowlinesFeatures: flowlines,
    flowlinesSelectSelectedFeatureId: selectedId,
  }) => {
    return (
      <div>
        <label>Flowline / Glacier</label>
        <select
          onChange={(e) => {
            doFlowlinesSelectByIdHandler(e.target.value);
          }}
          value={selectedId ? selectedId : ""}
          className="form-control"
        >
          <option value="">Select a Glacier</option>;
          {flowlines.map((feature, i) => {
            return (
              <option key={i} value={feature.getId()}>
                {feature.get("name")}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
);
