import React from "react";
import { connect } from "redux-bundler-react";
import AnimationControls from "./animation-controls";

export default connect(
  "selectFlowlinesSelectSelectedFeature",
  "selectModelConfigPerturbs",
  "selectModelConfigModelName",
  "doModelConfigClose",
  ({
    flowlinesSelectSelectedFeature: feature,
    modelConfigPerturbs,
    modelConfigModelName: modelName,
    doModelConfigClose,
  }) => {
    if (!feature) return null;
    return (
      <div className="container-fluid pt-2" style={{ overflow: "auto" }}>
        <div className="float-right">
          <button
            onClick={doModelConfigClose}
            className="btn btn-sm btn-link"
            title="Close current model run"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div>
          <strong>{feature.get("name")}</strong>
        </div>

        <hr />
        <div>Scenario: {modelName}</div>

        <div className="mt-3 mb-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Air Temperature (+/- °C):</span>
            <span>{`${modelConfigPerturbs.tempPerturb}${"°"}`}</span>
          </div>
          <small className="text-muted">
            Changes in air temperature can drive how fast glaciers melt, causing
            the glacier to lose mass faster or slower depending on scenario.
          </small>
        </div>

        <div className="mb-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Precipitation (%):</span>
            <span>{`${modelConfigPerturbs.precipPerturb}${"%"}`}</span>
          </div>
          <small className="text-muted">
            Changes in precipitation drive changes in a glacier in a race with
            mass loss by melt, adding mass or failing to replace mass lost to
            melting or calving.
          </small>
        </div>

        <div className="mb-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Sliding (%):</span>
            <span>{`${modelConfigPerturbs.frictPerturb}${"%"}`}</span>
          </div>
          <small className="text-muted">
            Sliding is a major contributor to the velocity of a glacier,
            speeding up sliding can shorten a glaciers life by moving more ice
            to below the equilibrium line more quickly than by internal
            deformation alone.
          </small>
        </div>

        <hr />

        <div className="mb-3">
          <div>Model Animation</div>
          <small className="text-muted">
            Use the animation controls below to investigate how the conditions
            described above affect the future of the selected glacier in the
            plot.
          </small>
        </div>

        <AnimationControls />
      </div>
    );
  }
);
