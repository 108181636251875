import React from "react";
import "../css/spinner.css";

export default (type) => {
  // make it so we can have other options over time...
  switch (type) {
    default:
      return (
        <div className="spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      );
  }
};
