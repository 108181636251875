import React, { useState, useEffect } from "react";
import debounce from "../../../../utils/debounce";

const debouncedHandler = debounce((func, val) => {
  func(val);
}, 100);

const ConfigPanelSlider = ({
  label,
  helperText,
  defaultValue = 0,
  value = defaultValue,
  min = 0,
  max = 1,
  step = 0.1,
  suffix = "",
  onChange,
}) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    debouncedHandler(onChange, val);
  }, [val, onChange]);

  return (
    <div className="form-group mr-1 ml-1">
      <label className="form-label mt-4">{label}</label>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "stretch",
        }}
      >
        <input
          value={val}
          onChange={(e) => {
            setVal(Number(e.target.value));
          }}
          type="range"
          className="custom-range pr-1"
          min={min}
          max={max}
          step={step}
        ></input>
        <span
          style={{ minWidth: "45px", textAlign: "right" }}
        >{`${val}${suffix}`}</span>
      </div>
      <small className="text-muted">{helperText}</small>
    </div>
  );
};

export default ConfigPanelSlider;
