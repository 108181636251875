import React from "react";
import glaciir from "../../cm-plugins/glaciir";
import {
  simpleCoordinateDisplay,
  scalebar,
  CorpsMap,
  getViewFromProjection,
  layerTree,
} from "@corpsmap/cm";
import "@corpsmap/cm/dist/cm.css";

const view = getViewFromProjection({
  epsg: "EPSG:3031",
  def: "+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs ",
  centerLonLat: [0, -90],
  zoom: 2,
});

const Map = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 54,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <CorpsMap
        plugins={[
          simpleCoordinateDisplay,
          scalebar,
          layerTree,
          glaciir({ pole: "S" }),
        ]}
        view={view}
      />
    </div>
  );
};

export default Map;
