import IntroPanel from "../components/intro-panel/intro-panel";

export default {
  name: "introPanel",

  getReducer: () => {
    const initialData = {
      _shouldInit: true,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "INTRO_PANEL_INIT_STARTED":
        case "INTRO_PANEL_INIT_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doIntroPanelInit:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "INTRO_PANEL_INIT_STARTED",
        payload: {
          _shouldInit: false,
        },
      });

      store.doEastPanelOpen(IntroPanel);

      dispatch({
        type: "INTRO_PANEL_INIT_FINISHED",
        payload: {},
      });
    },

  reactIntroPanelShouldInit: (state) => {
    if (state.introPanel._shouldInit)
      return { actionCreator: "doIntroPanelInit" };
  },
};
