// import api from "../util/api";
import { createSelector } from "redux-bundler";
import IntroPanel from "../components/intro-panel/intro-panel";
import NewModelPanel from "../components/model-config-panel/new-config-panel";

export default {
  name: "modelConfig",

  getReducer: () => {
    const initialData = {
      id: null,
      modelName: "",
      frictPerturb: 100,
      precipPerturb: 100,
      tempPerturb: 0,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MODEL_CONFIG_NEW":
        case "MODEL_CONFIG_OPENED":
        case "MODEL_CONFIG_CLOSED":
        case "MODEL_CONFIG_UPDATED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doModelConfigNew:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MODEL_CONFIG_NEW",
        payload: {},
      });
      store.doEastPanelOpen(NewModelPanel);
    },

  doModelConfigOpen:
    (model) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MODEL_CONFIG_OPENED",
        payload: {
          id: model.id,
          modelName: model.name,
          frictPerturb: model.frict_perturb,
          precipPerturb: model.precip_perturb,
          tempPerturb: model.temp_perturb,
        },
      });
      store.doModelOutputOpen();
    },

  doModelConfigClose:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "MODEL_CONFIG_CLOSED",
        payload: {
          id: null,
          modelName: "",
          frictPerturb: 100,
          precipPerturb: 100,
          tempPerturb: 0,
        },
      });
      store.doEastPanelOpen(IntroPanel);
    },

  doModelConfigUpdate:
    (newState) =>
    ({ dispatch }) => {
      dispatch({
        type: "MODEL_CONFIG_UPDATED",
        payload: newState,
      });
    },

  selectModelConfigId: (state) => {
    return state.modelConfig.id;
  },

  selectModelConfigModelName: (state) => {
    return state.modelConfig.modelName;
  },

  selectModelConfigFrictPerturb: (state) => {
    return state.modelConfig.frictPerturb;
  },

  selectModelConfigPrecipPerturb: (state) => {
    return state.modelConfig.precipPerturb;
  },

  selectModelConfigTempPerturb: (state) => {
    return state.modelConfig.tempPerturb;
  },

  selectModelConfigPerturbs: createSelector(
    "selectModelConfigPrecipPerturb",
    "selectModelConfigTempPerturb",
    "selectModelConfigFrictPerturb",
    (precipPerturb, tempPerturb, frictPerturb) => {
      return { precipPerturb, tempPerturb, frictPerturb };
    }
  ),
};
