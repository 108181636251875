import { Stroke, Style, Circle as CircleStyle } from "ol/style";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import GeoJSON from "ol/format/GeoJSON";
import { createSelector } from "redux-bundler";
import api from "../util/api";

export default {
  name: "flowlinesData",

  getReducer: () => {
    const initialData = {
      cache: {},
      lastFetchedId: null,
      layer: null,
      pointSource: null,
      _shouldFetch: false,
      _shouldInit: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        // FLOWLINES_INIT_FINISHED insures that the
        // map has already initialized and that this layer
        // will be added on top of the flowline layers
        case "FLOWLINES_INIT_FINISHED":
          return Object.assign({}, state, {
            _shouldInit: true,
          });
        case "FLOWLINES_SELECT_SELECTION_CHANGED":
          return Object.assign({}, state, {
            _shouldFetch: !!payload.selectedFeature,
          });
        case "FLOWLINES_DATA_FETCH_STARTED":
        case "FLOWLINES_DATA_FETCH_FINISHED":
        case "FLOWLINES_DATA_FETCH_ABORTED":
        case "FLOWLINES_DATA_INIT_STARTED":
        case "FLOWLINES_DATA_INIT_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFlowlinesDataInit:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "FLOWLINES_DATA_INIT_STARTED",
        payload: {
          _shouldInit: false,
        },
      });

      const map = store.selectMap();

      // the layer is used by the map to display
      // points as they are highlighted
      const layer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          image: new CircleStyle({
            radius: 6,
            fill: null,
            stroke: new Stroke({ color: "red", width: 2 }),
          }),
        }),
        zIndex: 1000, // above the flowlines
      });

      // this pointSource acts as our in memory feature
      // bucket, not used by the map
      const pointSource = new VectorSource();

      map.addLayer(layer);

      dispatch({
        type: "FLOWLINES_DATA_INIT_FINISHED",
        payload: {
          layer: layer,
          pointSource: pointSource,
        },
      });
    },

  doFlowlinesDataFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "FLOWLINES_DATA_FETCH_STARTED",
        payload: {
          _shouldFetch: false,
        },
      });

      const id = store.selectFlowlinesSelectSelectedFeatureId();
      const lastFetchedId = store.selectFlowlinesDataLastFetchedId();

      if (id !== lastFetchedId) {
        const cache = store.selectFlowlinesDataCache();
        const source = store.selectFlowlinesDataPointSource();
        source.clear();

        if (!cache.hasOwnProperty(id)) {
          api.get(`flowline/${id}`, (data) => {
            const features = new GeoJSON().readFeatures(data, {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:3413",
            });
            features.forEach((f) => {
              const id = f.get("id");
              if (id) f.setId(id);
            });
            source.addFeatures(features);
            dispatch({
              type: "FLOWLINES_DATA_FETCH_FINISHED",
              payload: {
                cache: Object.assign({}, cache, {
                  [id]: features,
                }),
                lastFetchedId: id,
              },
            });
          });
        } else {
          const features = cache[id];
          source.addFeatures(features);
          dispatch({
            type: "FLOWLINES_DATA_FETCH_FINISHED",
            payload: {
              lastFetchedId: id,
            },
          });
        }
      } else {
        dispatch({
          type: "FLOWLINES_DATA_FETCH_ABORTED",
          payload: {},
        });
      }
    },

  doFlowlinesDataAddHighlight:
    (id) =>
    ({ store }) => {
      // get our feature out of the in-memory source
      const pointSource = store.selectFlowlinesDataPointSource();
      const feature = pointSource.getFeatureById(id);
      // add it to our map layer if we found one
      if (feature) {
        const layer = store.selectFlowlinesDataLayer();
        const source = layer.getSource();
        source.addFeature(feature);
      }
    },

  doFlowlinesDataClearHighlight:
    () =>
    ({ store }) => {
      const layer = store.selectFlowlinesDataLayer();
      const source = layer.getSource();
      source.clear();
    },

  selectFlowlinesDataCompositeSeries: createSelector(
    "selectFlowlinesDataLastFetchedId",
    "selectFlowlinesSelectSelectedFeatureTrend",
    "selectFlowlinesDataPointSource",
    (_, trend, src) => {
      if (!src) return null;
      const features = src.getFeatures();
      const N = features.length;
      const glacier = {
        ids: [],
        x: [],
        y: [],
        name: "Glacier",
        type: "scattergl",
        mode: "lines",
        marker: { color: "blue" },
        fill: "tozeroy",
        fillcolor: "rgba(50, 123, 168,0.5)",
        line: { color: "transparent" },
        showlegend: true,
      };
      const velocity = {
        ids: [],
        x: [],
        y: [],
        name: "Obs. Velocity",
        type: "scattergl",
        mode: "lines",
        marker: { color: "transparent" },
        line: { color: "red" },
        showlegend: true,
        yaxis: "y2",
      };
      const base_elev = {
        ids: [],
        x: [],
        y: [],
        name: "Basal Elevation",
        type: "scattergl",
        mode: "lines",
        marker: { color: "transparent" },
        line: { color: "grey" },
        showlegend: false,
      };
      const sortOrder = trend === "r2l" ? -1 : 1;
      features
        .sort((a, b) => {
          if (a.get("dist_from_divide") > b.get("dist_from_divide"))
            return 1 * sortOrder;
          if (a.get("dist_from_divide") < b.get("dist_from_divide"))
            return -1 * sortOrder;
          return 0;
        })
        .forEach((f, i) => {
          const properties = f.getProperties();
          // create glacier geometry
          if (properties.thickness > 0) {
            glacier.ids.push(properties.id);
            glacier.x[i] = i;
            glacier.x[2 * N - i] = i;
            glacier.y[i] = properties.surface_elev;
            glacier.y[2 * N - i] = properties.base_elev;

            // create velocity line
            velocity.ids.push(properties.id);
            velocity.x[i] = i;
            velocity.y[i] = properties.velocity;
          }
          base_elev.ids.push(properties.id);
          base_elev.x[i] = i;
          base_elev.y[i] = properties.base_elev;
        });
      return [glacier, velocity, base_elev];
    }
  ),

  selectFlowlinesDataSeries: createSelector(
    "selectFlowlinesDataLastFetchedId",
    "selectFlowlinesSelectSelectedFeatureTrend",
    "selectFlowlinesDataPointSource",
    (_, trend, src) => {
      if (!src) return null;

      const plotableSeries = {
        "Surface Elevation": "surface_elev",
        "Bed Elevation": "base_elev",
        Dist: "dist_from_divide",
        Thickness: "thickness",
        Width: "width",
      };
      const features = src.getFeatures();
      const series = {};
      const sortOrder = trend === "r2l" ? -1 : 1;
      features
        .sort((a, b) => {
          if (a.get("dist_from_divide") > b.get("dist_from_divide"))
            return 1 * sortOrder;
          if (a.get("dist_from_divide") < b.get("dist_from_divide"))
            return -1 * sortOrder;
          return 0;
        })
        .forEach((f, i) => {
          const properties = f.getProperties();
          Object.keys(plotableSeries).forEach((key) => {
            if (!series.hasOwnProperty(key)) {
              series[key] = {
                ids: [],
                x: [],
                y: [],
                name: key,
                type: "scattergl",
                mode: "lines",
                marker: { color: "blue" },
              };
            }
            series[key].ids.push(properties.id);
            series[key].x.push(i);
            series[key].y.push(properties[plotableSeries[key]]);
          });
        });
      return series;
    }
  ),

  selectFlowlinesDataForModelRun: createSelector(
    "selectFlowlinesDataLastFetchedId",
    "selectFlowlinesDataPointSource",
    "selectModelConfigPerturbs",
    (_, src, perturbs) => {
      if (!src) return null;
      const features = src.getFeatures();
      const data = {
        clickedIndex: 29,
        flowline: {},
        avgFlowline: {},
      };
      features
        .sort((a, b) => {
          if (a.get("dist_from_divide") > b.get("dist_from_divide")) return -1;
          if (a.get("dist_from_divide") < b.get("dist_from_divide")) return 1;
          return 0;
        })
        .forEach((f, i) => {
          const p = f.getProperties();
          data.flowline[i] = {
            width: p.width,
          };
          data.avgFlowline[i] = {
            smb: p.smb,
            velocity: p.velocity,
            surface: p.surface_elev,
            thickness: p.thickness,
            t2m: p.t2m,
            precip0: p.precip0,
            precip1: p.precip1,
            precip2: p.precip2,
            precip3: p.precip3,
            precip4: p.precip4,
            precip5: p.precip5,
            precip6: p.precip6,
            precip7: p.precip7,
            precip8: p.precip8,
            precip9: p.precip9,
            precip10: p.precip10,
            precip11: p.precip11,
            bed: p.base_elev,
          };
        });
      return Object.assign(data, perturbs);
    }
  ),

  selectFlowlinesDataCache: (state) => {
    return state.flowlinesData.cache;
  },

  selectFlowlinesDataLastFetchedId: (state) => {
    return state.flowlinesData.lastFetchedId;
  },

  selectFlowlinesDataLayer: (state) => {
    return state.flowlinesData.layer;
  },

  selectFlowlinesDataPointSource: (state) => {
    return state.flowlinesData.pointSource;
  },

  reactFlowlinesDataShouldFetch: (state) => {
    if (state.flowlinesData._shouldFetch)
      return { actionCreator: "doFlowlinesDataFetch" };
  },

  reactFlowlinesDataShouldInit: (state) => {
    if (state.flowlinesData._shouldInit)
      return { actionCreator: "doFlowlinesDataInit" };
  },
};
