import React from "react";
import { connect } from "redux-bundler-react";
import IntroMessage from "./intro-message";
import FlowlinePicker from "./flowline-picker";
import ModelsList from "./models-list";

const IntroPanel = connect(() => {
  return (
    <div className="container-fluid mt-4" style={{ overflowY: "auto" }}>
      {/* <IntroMessage /> */}
      <FlowlinePicker />
      <ModelsList />
    </div>
  );
});

export default IntroPanel;
