import React from "react";
import { connect } from "redux-bundler-react";

const ModelsListItem = connect(
  "doModelConfigOpen",
  ({ doModelConfigOpen, model }) => {
    const handleOpen = () => {
      doModelConfigOpen(model);
    };

    return (
      <li
        onClick={handleOpen}
        className="list-group-item list-group-item-action"
      >
        <span className="float-right">
          <i className="text-success mdi mdi-arrow-right-drop-circle"></i>
        </span>
        {model.name}
      </li>
    );
  }
);

export default connect(
  "selectFlowlinesSelectSelectedFeatureId",
  "selectFlowlinesModels",
  "doModelConfigNew",
  ({
    flowlinesSelectSelectedFeatureId: selectedId,
    flowlinesModels: models,
    doModelConfigNew,
  }) => {
    return (
      <div className="mt-3">
        <label>Model Runs</label>
        {selectedId ? (
          <ul className="list-group">
            {models.map((model, i) => {
              return <ModelsListItem key={model.id} model={model} />;
            })}
            <li
              onClick={doModelConfigNew}
              className="list-group-item list-group-item-action"
            >
              <span className="float-right">
                <i className="text-primary mdi mdi-plus-circle"></i>
              </span>
              Create Custom Run
            </li>
          </ul>
        ) : (
          <p className="text-muted">Select a flowline...</p>
        )}
      </div>
    );
  }
);
