import bundle from './bundle';
import ZoomHomeButton from './button';

export default {
  components: [{
    component: ZoomHomeButton,
    region: "left-sidebar-top",
    sortOrder: 10,
  },],
  bundle: bundle
}